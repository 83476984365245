<template>
  <div class="create">
    <div class="create__header">
      <h2 class="create__header--title create__headerName">Encuestas</h2>
      <a class="create__header--return" @click="backViewPolls"><iconic name="close"></iconic></a>
    </div>
    <div class="create__option">
      <p :class="sectionStatus.questions ? 'select' : 'create__option--item-active'" @click="changeSection('questions')">Preguntas</p>
      <p :class="sectionStatus.settings ? 'select' : 'create__option--item-' + isStatusEdit()" @click="changeSection('settings')">Configuración</p>
      <p :class="sectionStatus.answers ? 'select' : 'create__option--item-' + isStatusEdit()" @click="changeSection('answers')">Respuestas</p>
    </div>
    <form id="poll">
      <div v-show="sectionStatus.questions">
        <div class="create__name">
          <div class="create__name--container">
            <label for="title" class="create__name--label">Titulo</label>
            <input class="create__name--input" id="nombre" type="text" placeholder="Nombre de la encuesta" name="title" v-model="title" @input="validateInput($event, `title`)" required />
          </div>
          <div class="create__name--container">
            <label for="description" class="create__name--label">Descripción</label>
            <input class="create__name--input" id="description" name="description" type="text" placeholder="Descripción" v-model="description" @input="validateInput($event, `description`)" required />
          </div>
        </div>
        <div>
          <div ref="askContainer" class="ask-container">
            <Ask
              v-for="(askComponent, index) in askComponentsInfo"
              :key="index"
              :idx="askComponent + 1"
              :question="$route.params.id ? pollData?.questions[index] : ''"
              :howManyQuestions="askComponentsInfo.length"
              @deleteComponent="deleteComponent"
            />
          </div>
          <div class="create__btn">
            <div class="btnCreate" @click="addAskComponent">
              <iconic name="plus"></iconic>
              <span class="tooltiptext">Nueva pregunta</span>
            </div>
          </div>
        </div>
        <div class="create__btns">
          <button type="button" class="create__btns--cancel" @click="backViewPolls">Cancelar</button>
          <button type="button" class="create__btns--draft">Guardar borrador</button>
          <button type="button" class="create__btns--save" @click="sendData">Publicar</button>
        </div>
      </div>
      <div v-show="sectionStatus.settings"><Settings :dataId="$route.params.id" :poll="pollData" /></div>
      <div v-show="sectionStatus.answers"><Answers /></div>
    </form>
  </div>
</template>
<script>
  import axios from "@/api/axios";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "CreatePoll",
    components: {
      Ask: () => import("./Pregunta.vue"),
      Settings: () => import("./Settings.vue"),
      Answers: () => import("./Answers/Answers.vue"),
    },
    data() {
      return {
        title: "",
        description: "",
        askComponentsInfo: [0],
        pollData: null,
        sectionStatus: {
          settings: false,
          questions: true,
          answers: false,
        },
        policiesView: {
          cities: [],
          offices: [],
          areas: [],
          users: [],
          roles: Object.keys(this.$global.dictionary.roles_es_auto),
        },
        formAction: "post",
      };
    },
    computed: {
      ...mapGetters("polls", ["getPoll"]),
      namePoll() {
        const maxLength = 60;
        let inputString = this.title;
        if (inputString.length > maxLength) {
          inputString = inputString.substring(0, maxLength);
        }
        return inputString !== "" ? inputString : "Encuesta";
      },
    },
    methods: {
      ...mapActions("polls", ["getPollById"]),
      isStatusEdit() {
        return this.$route.params.id ? "active" : "inactive";
      },
      changeSection(section) {
        if (["settings", "answers"].includes(section) && !this.$route.params.id) {
          this.$alerts.dataInfo({ message: "No se ha creado ninguna encuesta" });
          return;
        }
        for (const key in this.sectionStatus) {
          this.sectionStatus[key] = key === section;
        }
      },
      dataValidation() {
        const form = document.querySelector("#poll");
        const requiredField = form.querySelectorAll("[required]");
        requiredField.forEach((field) => {
          this.validateInput(field, { type: field.type == "file" ? "image" : field.type });
        });
        return this.$validate.validateForm(`#poll`).length != 0;
      },
      pollExists() {
        if (this.$route.params.id) {
          this.pollData = this.getPoll.pollInProgress;
          const { title, description, questions } = this.pollData;
          this.title = title || "";
          this.description = description || "";
          this.askComponentsInfo = questions.length;
        }
      },
      async sendData() {
        const formData = new FormData(document.getElementById("poll"));
        if (this.dataValidation()) {
          alert("Hay algunos datos sin rellenar");
          return;
        }
        this.sortFieldName();
        this.$alerts.dataSending("Creando Encuesta...");
        formData.append("policiesView", JSON.stringify(this.policiesView));
        const newpoll = await this.$store.dispatch("polls/createNewPoll", formData);
        if (newpoll._id) {
          this.$alerts.closeSwal();
          this.pollData = newpoll;
          return this.$router.push(`/polls/editPoll/${newpoll._id}`).catch(() => {});
        }
      },
      sortFieldName() {
        document.querySelectorAll(".ask").forEach((ask, i) => {
          ask.querySelectorAll("input[name]").forEach((input, j) => {
            const nameField = input.getAttribute("name");
            const [firstNamePart, lastNamePart = ""] = nameField.split("/");
            input.setAttribute("name", `${firstNamePart.split("-")[0]}-${i}${lastNamePart ? `/${lastNamePart}` : ""}`);
          });
        });
      },
      deleteComponent(index) {
        this.askComponentsInfo.splice(index, 0);
      },
      validateInput: function(evt, { type }) {
        if (type == "image") {
          const fieldInput = document.querySelector(`input[name='${evt.className}']`);
          this.$validate.successInput(fieldInput);
          return;
        }
        return this.$validate.validateInput(evt, type);
      },
      async loadPollById() {
        if (this.$route.params.id) {
          await this.getPollById(this.$route.params.id);
          this.pollExists();
        }
      },

      backViewPolls() {
        this.$router.push({ name: "polls" });
      },

      async fetchCities() {
        try {
          const { data = [] } = await axios.get("/cities/");

          this.policiesView.cities = data.map((element) => ({ name: element.cityName, _id: element._id }));
        } catch (error) {
          console.error("Error al obtener las ciudades:", error);
        }
      },
      async fetchOffices() {
        try {
          const { data = [] } = await axios.get("/offices/");

          this.policiesView.offices = data;
        } catch (error) {
          console.error("Error al obtener las oficinas:", error);
        }
      },
      addAskComponent() {
        this.askComponentsInfo.push(this.askComponentsInfo.length);
        this.$nextTick(() => {
          const askContainer = this.$refs.askContainer;
          const lastAskComponent = askContainer.lastChild;
          if (lastAskComponent) {
            lastAskComponent.scrollIntoView({ behavior: "smooth" });
          }
        });
      },
    },
    mounted() {
      this.fetchCities();
      this.fetchOffices();
      this.loadPollById();
    },
  };
</script>
<style lang="scss">
  .create {
    position: relative;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    &__header {
      @include Flex(row, space-between);
      width: 98%;
      &--return {
        cursor: pointer;
        font-weight: bold;
        color: #5b5957;
      }
    }

    &__headerName {
      font-size: 25px;
    }
    &__option {
      @include Flex();
      max-width: 100%;
      border-bottom: 1px solid #d8d8d8;
      .select {
        padding: 10px;
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 2px solid $primary-color;
      }
      &--item-active {
        padding: 10px 0;
        margin-right: 10px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          color: $primary-color;
          font-weight: 600;
        }
      }
      &--item-inactive {
        padding: 10px 0;
        margin-right: 10px;
        font-size: 14px;
        color: #6c757d;
        &:hover {
          cursor: no-drop;
          font-weight: 600;
        }
      }
    }
    &__name {
      width: 100%;
      max-width: 1440px;
      height: 172px;
      margin: 1% 0;
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 20px 20px 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      &--container {
        height: 65px;
        margin: 2px 0;
      }
      &--label {
        font-size: 16px;
        text-align: left;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0em;
        font-family: $first_font;
      }
      &--input {
        width: 100%;
        height: 39px;
        border-radius: 4px;
        padding: 5px;
        border: 0.5px solid #a8a9a9;
        cursor: text;
        &::placeholder {
          padding: 0;
        }
      }
    }
    &__btn {
      position: relative;
      text-align: center;
      top: -290px;
      left: 97.5%;
      .btnCreate {
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        color: #fff;
        border-radius: 50%;
        background-color: $primary_color;
        .tooltiptext {
          top: 0px;
          left: 40px;
          opacity: 0.8;
          width: 150px;
          position: absolute;
          border-radius: 6px;
          visibility: hidden;
          padding: 3px 0 3px 5px;
          background-color: #000;
          &::after {
            content: "";
            top: 8px;
            left: -4px;
            border-width: 7px;
            margin-left: -10px;
            position: absolute;
            border-style: solid;
            border-color: transparent #000 transparent transparent;
          }
        }
        :nth-child(1) {
          margin-top: 3.5px;
        }
        &:hover {
          transform: scale(1.05);
          background: $primary_color;
          transition: all 0.3s ease-in-out;
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }
    .data-error {
      background: $primary_color;
      &::placeholder {
        color: #fff;
      }
    }
    &__btns {
      width: 75.5%;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: center;
      gap: 15px;
      height: 70px;
      padding: 20px 0;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
      background: #fff;

      &--cancel {
        border: 1px solid $primary_color;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        color: #bd0909;
        cursor: pointer;
      }
      &--draft {
        border: 1px solid #bd090940;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        background: #bd090940;
        color: #bd0909;
        cursor: pointer;
      }
      &--save {
        border: 1px solid #bd0909;
        height: 35px;
        padding: 5px 10px;
        border-radius: 4px;
        background: #bd0909;
        color: #fff;
        cursor: pointer;
      }
    }
    @media (min-width: 424px) {
      &__option {
        justify-content: flex-start;
      }
    }
  }
</style>
